<template>
    <div class="main-content">
        <div class="container lfont">
            <v-alert dense text type="success">
                ທ່ານສະຫມັກຕຳແໜ່ງ <strong>{{ position }}</strong> ສຳເລັດ ລໍຖ້າຕິດຕໍ່ກັບພາຍໃນ <strong>15 ມື້</strong>
                <p>ID: {{ id }}</p>
                <p>name: {{ name }}</p>
                <p>tel: {{ tel }}</p>
            </v-alert>

        </div>
    </div>
</template>

<script>
export default {
    props: {
        id: {
            type: String,
            required: true
        },
        name: {
            type: String,
            required: true
        },
        tel: {
            type: String,
            required: true
        },
        position: {
            type: String,
            required: true
        }
    }
}
</script>

<style lang="scss" scoped></style>